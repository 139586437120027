import { useCallback, useState } from 'react';

/* eslint-disable no-shadow, no-unused-vars */
export enum HubbleSurveys {
  ParticipantsV2OptIn = 'f19b64f6-1b8d-4f22-bc1e-c467b823d075',
}

declare module Hubble {
  const isReady: boolean;
  function show(s: string): void;
}

const HUBBLE_SCRIPT_NAME = 'Hubble';

export function useHubble() {
  const hubble = () => {
    // @ts-ignore
    if (window && window[HUBBLE_SCRIPT_NAME]) {
      // @ts-ignore
      return window[HUBBLE_SCRIPT_NAME];
    }
    return null;
  };

  const [isReady, setIsReady] = useState(!!hubble());
  let isReadyInterval: number | undefined;
  if (!isReady) {
    isReadyInterval = window.setInterval(() => {
      if (hubble()) {
        setIsReady(true);
        clearInterval(isReadyInterval);
      }
    }, 200);
  }

  const show = useCallback(
    (survey: HubbleSurveys) => {
      if (isReady) {
        hubble().show(survey);
      } else {
        console.warn('Hubble is not set up in this environment');
        throw new Error('Hubble is not set up in this environment');
      }
    },
    [isReady],
  );

  const hide = () => {
    const shadowRoot = document.getElementsByTagName('hubble-container')[0].shadowRoot; // eslint-disable-line
    if (!shadowRoot) {
      return;
    }

    const closeButton: HTMLElement | null = shadowRoot.querySelector('.modal__close');
    if (closeButton) {
      closeButton.click();
    }
  };

  const identify = (userId: string, email: string) => {
    try {
      const hub = hubble();
      if (hub) {
        hub.identify(userId, { email });
      }
    } catch (e) {
      console.error('Error identifying user on hubble', userId);
    }
  };

  return {
    hide,
    show,
    isReady,
    identify,
  };
}
